import React, { useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { ReactReduxContext, useSelector } from 'react-redux';
import { useContextBridge } from '@react-three/drei';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { Grid } from 'react-loader-spinner';
import loadable from '@loadable/component';
import imgRevealAnimation from '../../animations/imgRevealAnimation';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Model = loadable(() => import('./Model'));
const RightPanel = loadable(() => import('./RightPanel'));
const ModelMobile = loadable(() => import('./ModelMobile'));

const StyledCanvasWrapperForCursor = styled.div`
	aspect-ratio: 1/1;
	cursor: default;
	height: auto;
	position: absolute;
	top: 0 !important;
	width: 100vw;

	@media (min-width: 768px) {
		top: -162px !important;
	}

	@media (min-width: 1024px) {
		height: 100vh;
		top: -162px !important;
	}
`;

const StyledCanvas = styled(Canvas)`
	//height: 100vh !important;
	//margin-left: -4.8%;
	//position: absolute !important;
	//top: -162px !important;
	//width: 100vw !important;
	@media (min-width: 1024px) {
		&.even {
			height: calc(100vh - 1px) !important;
		}
	}
`;

export const StyledFlatPlanPanel = styled.div`
	//position: absolute;
	//top: 100vh;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.plansBgCream};
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: flex-start;
	left: 0;
	padding: 95px 0 75px;
	position: relative;
	width: 100%;
	@media (min-width: 1024px) {
		flex-direction: row-reverse;
		justify-content: center;
		width: 60%;
		padding: 95px 0;
		top: unset;
		position: absolute;
	}
	&.apartments-list {
		position: absolute;
		top: 100vh;
		@media (min-width: 1024px) {
			top: unset;
			position: absolute;
		}
	}

	.reveal {
		background-color: ${({ theme }) => theme.colors.plansBgCream};
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		@media (min-width: 1024px) {
			width: 101%;
		}
	}
`;

export const StyledSmallPlanWrapper = styled.div`
	align-items: flex-end;
	display: flex;
	img {
		//width: 200px;
		width: 150px;
	}

	@media (min-width: 1024px) {
		img {
			width: 200px;
		}
	}
`;

export const StyledImgWrapper = styled.div`
	height: calc(100vh - 150px - 200px);
	position: relative;
	img {
		height: 100%;
	}
	@media (min-width: 1024px) {
		height: 100%;
		width: auto;
		img {
			width: auto;
			height: 100%;
		}
	}
`;

const StyledLoaderWrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.creamyLight_50};
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
`;

const Apartments3dModule = ({ data, activeLang, isMobile, activeFloor, setActiveFloor }) => {
	// const [activeFloor, setActiveFloor] = useState(-1);
	const [isMobileDevice, setIsMobile] = useState(false);
	const [isModelInteractive, setIsModelInteractive] = useState(false);
	const [isEvenHeight, setIsEvenHeight] = useState(false);
	const ContextBridge = useContextBridge(ReactReduxContext);
	const canvasRef = useRef();
	const activeFlat = useSelector((state) => state.model.activeFlat);
	const apartmentsData = useSelector((state) => state.apartmentsData.apartmentsData);
	const activeBuilding = useSelector((state) => state.model.activeBuilding);
	const is3DModelViewActive = useSelector((state) => state.model.is3DModelViewActive);
	const activeApartmentData = apartmentsData.find((apartment) => apartment.number === activeFlat) || null;
	const apartmentPlanUrl = activeApartmentData?.gallery_floor_plan?.[2]?.original || '';
	const floorPlanUrl = activeApartmentData?.gallery_floor_plan?.[3]?.original || '';
	const isModelLoaded = useSelector((state) => state.model.isModelLoaded);
	const tl3 = gsap.timeline();

	const checkIfWindowHeightIsEven = () => {
		// bug fix for safari - if window height is even, text label on model are in wrong position
		const height = window?.innerHeight || 0;

		if (height % 2 === 0 && !isEvenHeight) {
			setIsEvenHeight(true);
		} else if (height % 2 !== 0 && isEvenHeight) {
			setIsEvenHeight(false);
		}
	};
	const onWindowResize = () => {
		if (typeof window !== 'undefined') {
			const width = window?.innerWidth || 0;
			if (width < 1024 && isModelInteractive) {
				setIsModelInteractive(false);
			} else if (width >= 1024 && !isModelInteractive) {
				setIsModelInteractive(true);
			}
			checkIfWindowHeightIsEven();
		}
	};

	useEffect(() => {
		setIsMobile(isMobile);
	}, []);

	useEffect(() => {
		onWindowResize();
		window.addEventListener('resize', onWindowResize);

		return () => {
			window.removeEventListener('resize', onWindowResize);
		};
	}, [isModelInteractive, isEvenHeight]);

	useEffect(() => {
		const reveal = document.querySelectorAll('.reveal');
		if (activeFlat !== '') {
			imgRevealAnimation(tl3, reveal);
		}
	}, [activeFlat]);

	return (
		<>
			{!isModelLoaded && (
				<StyledLoaderWrapper>
					<Grid height="30" width="30" color="rgba(28,28,28, 0.5)" ariaLabel="loading" />
				</StyledLoaderWrapper>
			)}
			<StyledCanvasWrapperForCursor data-cursor="hidden-mode">
				<StyledCanvas className={isEvenHeight ? 'even' : ''} ref={canvasRef}>
					<ContextBridge>
						{isModelInteractive ? (
							<Model canvasRef={canvasRef} activeFloor={activeFloor} setActiveFloor={setActiveFloor} data={data} />
						) : (
							<ModelMobile data={data} activeBuilding={activeBuilding} />
						)}
					</ContextBridge>
				</StyledCanvas>
			</StyledCanvasWrapperForCursor>
			{is3DModelViewActive && activeBuilding !== '' && (
				<RightPanel
					activeFloor={activeFloor}
					setActiveFloor={setActiveFloor}
					data={data}
					activeLang={activeLang}
					isMobile={isMobile}
				/>
			)}
			{activeFlat !== '' && (
				<StyledFlatPlanPanel>
					<StyledImgWrapper>
						<img src={apartmentPlanUrl} alt="apartment-plan" />
					</StyledImgWrapper>
					<StyledSmallPlanWrapper>
						<img src={floorPlanUrl} />
					</StyledSmallPlanWrapper>
					{!isMobileDevice && <div className="reveal" />}
				</StyledFlatPlanPanel>
			)}
		</>
	);
};

export default Apartments3dModule;
