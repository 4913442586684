import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';
import {
	setActiveBuilding,
	setActiveFlat,
	setIs3DModelViewActive,
	setIsModelLoaded,
	setIsRightPanelFromListOpened,
} from '../state/modelSlice';
import Apartments3dModule from '../components/apartments3dModule/Apartments3dModule';
import { setIsAskAboutPricePopupOpen } from '../state/askAboutPricePopupSlice';
import { setScaleIntroAnimation } from '../state/scaleIntroAnim';
import { setMenuColor } from '../state/menuColor';
import { setIntroStartModule } from '../state/introStartModule';
import { setIntroLoadedOnce } from '../state/introLoadedOnce';
import { setIsDropdownMenu } from '../state/isDropdownMenuSlice';
import Seo from '../components/seo/Seo';
import { StaticImage } from 'gatsby-plugin-image';
import PageContainer from '../common/PageContainer';

const Filtration = loadable(() => import('../components/apartments/Filtration'));
const ApartmentsList = loadable(() => import('../components/apartments/ApartmentsList'));
const ChooseBuildingPanel = loadable(() => import('../components/apartments3dModule/ChooseBuildingPanel'));
const AskAboutPricePopup = loadable(() => import('../components/askAboutPricePopup/AskAboutPricePopup'));

const StyledPageContainer = styled(PageContainer)`
	padding-bottom: 20px;
	@media (min-width: 768px) {
		padding-bottom: 40px;
	}
	@media (min-width: 1024px) {
		padding-bottom: unset;
	}
`;

const StyledApartmentsContainerMobile = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-top: ${({ is3DModelViewActive }) => (is3DModelViewActive ? '0' : '110px')};
	position: relative;
	transition: height 0.3s ease-in-out;
	width: 100vw;
	//height: var(--inner-height);
	height: 100%;
	//min-height: ${({ is3DModelViewActive }) => (is3DModelViewActive ? `var(--inner-height)` : 'unset')};
	min-height: inherit;
	@media (min-width: 768px) {
		overflow: hidden;
		padding-top: 162px;
	}
	&.building-selected {
		overflow: visible;
		padding-top: 0;
		::-webkit-scrollbar {
			background: transparent;
			width: 0;
		}
		@media (min-width: 768px) {
			padding-top: ${({ is3DModelViewActive }) => (is3DModelViewActive ? '0' : '162px')};
			overflow: visible;
		}
	}

	&.scroll-blocked {
		height: 200vh;
		//height: 250vh;
		//overflow: hidden;
		overflow: scroll;
		padding-top: 0;
	}
	//@media (min-width: 1024px) {
	//	display: none;
	//}
`;

const StyledApartmentsContainer = styled.div`
	display: flex;
	@media (min-width: 1024px) {
		height: 100vh;
		//height: calc(var(--inner-height));
		//max-height: var(--inner-height);
		overflow: hidden;
		padding-top: 162px;
		flex-direction: column;
		width: 100vw;
		transition: height 0.3s ease-in-out;
	}
	&.scroll-blocked {
		height: 200vh;
		overflow: hidden;
		@media (min-width: 1024px) {
			height: 100vh;
		}
	}
`;

const StyledApartmentListWrapper = styled.div`
	display: none;
	&.active {
		display: contents;
	}
`;

const StyledModelWrapper = styled.div`
	display: none;

	&.active {
		display: contents;
	}
`;

const Styled3DModelWrapper = styled.div`
	height: var(--inner-height);
	//height: 100vh;
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	width: 100%;
	@media (min-width: 1024px) {
		height: 100vh;
	}
`;

const StyledBottomBarWrapper = styled.div`
	//position: absolute;
	bottom: 0;
	display: flex;
	flex-direction: column;
	height: 48px;
	justify-content: flex-start;
	position: fixed;
	width: 100vw;
	&.mobile {
		background-color: ${({ theme }) => theme.colors.creamyLight};
		bottom: 0 !important;
		display: ${({ isAnyApartmentSelected }) => (isAnyApartmentSelected ? 'none' : 'block')};
		position: fixed;
	}
	@media (min-width: 768px) {
		height: 60px;
	}
	@media (min-width: 1366px) {
		height: 64px;
	}
`;

const StyledLine = styled.div`
	height: 1px;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	&.light {
		background-color: ${({ theme }) => theme.colors.grey};
	}

	&.dark {
		background-color: ${({ theme }) => theme.colors.creamy};
	}
`;

const StyledContentWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: center;
	padding: 0 25px;
	width: 100%;
	@media (min-width: 768px) {
		justify-content: flex-start;
		padding: 0 64px;
	}
	@media (min-width: 1366px) {
		padding: 0 140px;
	}
	@media (min-width: 1920px) {
		padding: 0 calc((100vw - 1510px) / 2);
	}
	&.light {
		background-color: rgba(247, 245, 242, 0.3);
		color: ${({ theme }) => theme.colors.black};
	}
	&.dark {
		color: ${({ theme }) => theme.colors.grey};
	}
`;

const StyledViewSwitchButton = styled.div`
	color: ${({ theme }) => theme.colors.grey};
	cursor: pointer;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 10px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	letter-spacing: 0;
	line-height: 20px;
	text-transform: uppercase;
	&.active {
		color: ${({ theme }) => theme.colors.black};
		font-weight: ${({ theme }) => theme.fontWeight.bold};
	}
	&:first-child {
		margin-right: 22px;
	}
	&:hover {
		color: ${({ theme }) => theme.colors.black};
	}
	@media (min-width: 375px) {
		font-size: 12px;
	}
	@media (min-width: 390px) {
		font-size: 13px;
	}
	@media (min-width: 1024px) {
		font-size: 14px;
		line-height: 26px;
	}
`;

const ModelMockup = styled.div`
	position: relative;
	height: 50%;
`;

const ApartmentsPage = ({ data: { apartmentsData }, pageContext }) => {
	const [isMobile, setIsMobile] = useState(false);
	const [activeFloor, setActiveFloor] = useState(-1);
	const [isRightPanelOpened, setIsRightPanelOpened] = useState(false);

	const pageData = apartmentsData?.nodes?.[0] || '';
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || [];
	const seoData = pageData?.seoSettings || '';
	const activeLang = (pageContext?.locale === 'uk' ? 'ua' : pageContext?.locale) || '';
	const { tabs3dModel, tabsApartmentsList } = pageData;
	const activeBuilding = useSelector((state) => state.model.activeBuilding);
	const activeFlat = useSelector((state) => state.model.activeFlat);
	const is3DModelViewActive = useSelector((state) => state.model.is3DModelViewActive);
	const isRightPanelFromListOpened = useSelector((state) => state.model.isRightPanelFromListOpened);
	const dispatch = useDispatch();
	const isModelLoaded = useSelector((state) => state.model.isModelLoaded);

	const handleView = (value) => {
		if (window.location.hash.length > 0) {
			window.history.replaceState('', document.title, window.location.pathname);
			if (isRightPanelOpened) {
				setIsRightPanelOpened(false);
			}
		}

		dispatch(setIs3DModelViewActive(value));
		if (activeBuilding !== '') {
			dispatch(setActiveBuilding(''));
		}
		if (activeFloor !== -1) {
			setActiveFloor(-1);
		}
		if (activeFlat !== '') {
			dispatch(setActiveFlat(''));
		}
	};

	const handleResize = () => {
		const width = window.innerWidth;
		if (width < 1024 && !isMobile) {
			setIsMobile(true);
			dispatch(setIsDropdownMenu(true));
		} else if (width >= 1024 && isMobile) {
			setIsMobile(false);
		}
	};

	useEffect(() => {
		const hrefArray = window.location.href.split('#');
		if (hrefArray.length > 1) {
			dispatch(setIsRightPanelFromListOpened(true));
			dispatch(setIs3DModelViewActive(false));
		}
	}, []);

	useEffect(() => {
		handleResize();
		dispatch(setScaleIntroAnimation(true));
		dispatch(setIntroStartModule(false));
		dispatch(setMenuColor(false));
		dispatch(setIntroLoadedOnce(true));
		window.addEventListener('resize', handleResize);

		if (isModelLoaded) {
			dispatch(setIsModelLoaded(false));
		}

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isMobile]);

	const removeIframeFromDOM = () => {
		const iFrame = document.getElementById('iframe-noho-dolnychmlynow10');
		iFrame && iFrame.remove();
	};

	const closeAskAboutPricePopup = () => {
		dispatch(setIsAskAboutPricePopupOpen(false));
		removeIframeFromDOM();
	};

	useEffect(() => {
		const escFunction = (e) => {
			if (e.keyCode === 27) {
				closeAskAboutPricePopup();
			}
		};
		window.addEventListener('keydown', escFunction);
		return () => {
			window.removeEventListener('keydown', escFunction);
		};
	});

	const isAnyBuildingSelected = activeBuilding !== '';
	const isAnyApartmentSelected = activeFlat !== '';

	return (
		<>
			<Seo seoGlobal={seoGlobal} seoData={seoData} />
			{isMobile && (
				<StyledApartmentsContainerMobile
					is3DModelViewActive={is3DModelViewActive}
					className={isRightPanelFromListOpened ? 'scroll-blocked' : isAnyBuildingSelected ? 'building-selected' : ''}
				>
					{/*<StyledPageContainer className="content-standard-apartments">*/}
					{/*	<StaticImage src={`../assets/apartments/dm_makieta.png`} alt="DM" layout="constrained" />*/}
					{/*</StyledPageContainer>*/}
					<StyledApartmentListWrapper className={is3DModelViewActive ? '' : 'active'}>
						<Filtration data={pageData} />
						<ApartmentsList
							handleView={(value) => handleView(value)}
							apartmentsData={pageData}
							activeLang={activeLang}
							activeFloor={activeFloor}
							setActiveFloor={setActiveFloor}
							isRightPanelOpened={isRightPanelOpened}
							setIsRightPanelOpened={setIsRightPanelOpened}
						/>
					</StyledApartmentListWrapper>
					<StyledModelWrapper className={is3DModelViewActive ? 'active' : ''}>
						<Styled3DModelWrapper>
							<Apartments3dModule
								data={pageData}
								activeLang={activeLang}
								isMobile={isMobile}
								activeFloor={activeFloor}
								setActiveFloor={setActiveFloor}
							/>
						</Styled3DModelWrapper>
						<StyledBottomBarWrapper isAnyApartmentSelected={isAnyApartmentSelected || isAnyBuildingSelected} className="mobile">
							<StyledLine className={activeFlat !== '' ? 'light' : 'dark'} />
							<StyledContentWrapper className={activeFlat !== '' ? 'light' : 'dark'}>
								<StyledViewSwitchButton
									data-cursor="hidden-mode"
									className={is3DModelViewActive ? 'active' : ''}
									onClick={() => handleView(true)}
								>
									{tabs3dModel}
								</StyledViewSwitchButton>
								<StyledViewSwitchButton
									data-cursor="hidden-mode"
									className={!is3DModelViewActive ? 'active' : ''}
									onClick={() => handleView(false)}
								>
									{tabsApartmentsList}
								</StyledViewSwitchButton>
							</StyledContentWrapper>
						</StyledBottomBarWrapper>
					</StyledModelWrapper>
					{is3DModelViewActive && activeBuilding === '' && <ChooseBuildingPanel data={pageData} />}
				</StyledApartmentsContainerMobile>
			)}
			<AskAboutPricePopup handleRemoveIframe={() => removeIframeFromDOM()} data={pageData} />
			{!isMobile && (
				<StyledApartmentsContainer className={isRightPanelFromListOpened ? 'scroll-blocked' : ''}>
					<StyledApartmentListWrapper className={is3DModelViewActive ? '' : 'active'}>
						{/*<StyledPageContainer className="content-standard-apartments">*/}
						{/*	<StaticImage src={`../assets/apartments/dm_makieta.png`} alt="DM" layout="constrained" />*/}
						{/*</StyledPageContainer>*/}
						<Filtration data={pageData} />
						<ApartmentsList
							handleView={(value) => handleView(value)}
							apartmentsData={pageData}
							activeLang={activeLang}
							activeFloor={activeFloor}
							setActiveFloor={setActiveFloor}
							isRightPanelOpened={isRightPanelOpened}
							setIsRightPanelOpened={setIsRightPanelOpened}
						/>
					</StyledApartmentListWrapper>
					<StyledModelWrapper className={is3DModelViewActive ? 'active' : ''}>
						<Styled3DModelWrapper>
							<Apartments3dModule
								data={pageData}
								activeLang={activeLang}
								isMobile={isMobile}
								activeFloor={activeFloor}
								setActiveFloor={setActiveFloor}
							/>
						</Styled3DModelWrapper>
						<StyledBottomBarWrapper>
							<StyledLine className={activeFlat !== '' ? 'light' : 'dark'} />
							<StyledContentWrapper className={activeFlat !== '' ? 'light' : 'dark'}>
								<StyledViewSwitchButton
									data-cursor="hidden-mode"
									className={is3DModelViewActive ? 'active' : ''}
									// className="disabled"
									onClick={() => handleView(true)}
								>
									{tabs3dModel}
								</StyledViewSwitchButton>
								<StyledViewSwitchButton
									data-cursor="hidden-mode"
									className={!is3DModelViewActive ? 'active' : ''}
									onClick={() => handleView(false)}
								>
									{tabsApartmentsList}
								</StyledViewSwitchButton>
							</StyledContentWrapper>
						</StyledBottomBarWrapper>
					</StyledModelWrapper>
				</StyledApartmentsContainer>
			)}
		</>
	);
};

export default ApartmentsPage;

export const query = graphql`
	query ($locale: String) {
		apartmentsData: allDatoCmsApartmentsPage(filter: { locale: { eq: $locale } }) {
			nodes {
				slug
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
				filtrationAreaLabel
				filtrationPriceLabel
				filtrationRoomsLabel
				filtrationFloorLabel
				tableHeadingsFlatNumber
				tableHeadingsNumerOfRooms
				tableHeadingsFlatArea
				tableHeadingsFlatPrice
				tableHeadingsFloor
				tableHeadingsStatus
				tableHeadingsFlatCard
				tableHeadingsBuilding
				tableDataNoResults
				tableDataAskAboutPrice
				tabs3dModel
				tabsApartmentsList
				panelBuilding
				panelFloor
				panelApartment
				chooseBuildingText
				chooseFloorText
				buildingPanelBuildingAdditionalInfo {
					buildingPanelBuildingNumber
					buildingPanelBuildingInfoHeader
					buildingPanelBuildingInfoData
				}
				apartmentPanelLabel1
				apartmentPanelLabel2
				apartmentPanelLabel3
				apartmentPanelLabel4
				apartmentPanelLabel5
				apartmentPanelLabel6
				apartmentPanelLabelSameApartment
				apartmentPanelLabelContactUs
				apartmentPanelLabelPremisesCard
				apartmentPanelPlanTagLabelApartment
				apartmentPanelPlanTagLabelArea
				apartmentPanelPlanTagLabelStatus
				askAboutPriceFormHeader
				askAboutPriceFormSubheader
				modelBuildingText
				modelSoonText
				modelServicesText
				modelHotelText
			}
		}
	}
`;
